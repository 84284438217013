#root {
  height: 100%;
}

.App {
  height: 100%;
  background-color: rgb(255, 243, 205);
  overflow: hidden;
}

#mainApp {
  height: calc(100% - 50px);
}

#sharePanel {
  position: absolute;
  right: 50px;
  top: 10%;
  z-index: 101;
}

#sharePanelLandscape {
  display: flex;
  flex-direction: column;
}

#sharePanelPortrait {
  position: absolute;
  top: 0;
  right: 0;
}

.share-fab {
  height: 30px !important;
  width: 30px !important;
  min-width: 30px !important;
  min-height: 30px !important;
}

#nav {
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  /* margin-left: 20px; */
}

#navLogo {
  height: 80%;
  width: auto;
}

.nav-button-text {
  font-weight: bold;
  font-style: italic;
}

html,
body {
  position: relative;
  height: 100%;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#pageOne {
  background-color: rgb(255, 243, 205);
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

#pageTwo {
  background-color: rgb(255, 243, 205);
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-image: url(./resourses/pageTwoBg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

#pageThree {
  /* display: flex; */
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.timeline-xl {
  display: none;
}

.timeline-md {
  display: none;
}

.timeline-sm {
  display: none;
}

.timeline-headline {
  width: 1500px;
  height: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.timeline-headline-title {
  font-style: italic;
  font-weight: bolder;
  color: rgb(250, 101, 46) !important;
  text-shadow: rgb(255, 166, 83) 3px 3px;
  font-size: 55px;
  margin-left: -50px;
}

.timeline-headline-img {
  height: 95% !important;
  width: auto !important;
}

.time-line-card-title {
  font-style: italic;
  font-weight: bolder;
  color: rgb(250, 101, 46) !important;
  text-shadow: rgb(255, 166, 83) 3px 3px;
  font-size: 24px !important;
}

.time-line-sub-title {
  font-style: italic;
  font-weight: bold;
  color: #fa7e38 !important;
  text-shadow: 2px 2px rgb(211, 243, 94);
  font-size: 20px !important;
  height: 40px;
}

.time-line-card-content {
  font-size: 16px !important;
  margin-top: 8px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

#pageTwoSectionOne {
  height: 100%;
  width: 100%;
}

#pageTwoSectionTwo {
  height: 100%;
  width: 100%;
}

.page-two-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-two-logo {
  width: 35% !important;
  height: auto !important;
  min-width: 180px;
}

.container {
  /* background-color: #fff; */
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inner-container {
  display: flex;
  margin-top: 0px;
  padding: 0px calc(0.1*100vw) 20px calc(0.1*100vw);
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

#mainGif {
  width: auto;
  height: 100%;
}

.full-width {
  width: 100%;
}

.title {
  font-weight: bold;
  font-style: italic;
}

.margin {
  margin-bottom: 5px;
}

.button-text {
  font-size: 12px;
  font-weight: 600;
  font-style: italic;
}

#user-date-grid {
  display: grid;
  gap: 10px;
  /* background-color: #2196F3; */
  margin-top: 20px;
}

.data-grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data-grid-item-value {
  font-weight: bolder;
  font-style: italic;
  text-shadow: 2px 2px rgb(255, 187, 0);
  color: rgb(88, 183, 177);
}

.data-grid-item-title {
  font-weight: bold;
  font-style: italic;
  color: rgb(66, 165, 158);
}

.section-two-title {
  font-weight: bolder;
  font-style: italic;
  text-shadow: 2px 2px rgb(211, 243, 94);
  color: #fa7e38;
  margin-top: 12px;
  margin-bottom: 8px;
}

.section-two-des {
  font-weight: bolder;
  font-style: italic;
  color: grey;
  width: 70%;
}

#pageTwoGameGallery {
  display: grid;
  gap: 10px;
  grid-template-columns: auto auto;
}

.game-gallery-icon {
  width: 32vw;
  min-width: 120px;
  max-width: 200px;
  height: 18vw;
  min-height: 67.5px;
  max-height: 112.5px;
  border-color: white;
  border-width: 5px;
  border-style: solid;
  border-radius: 6px;
  background-color: burlywood;
}

#secTwoImg {
  width: 70%;
  height: auto;
  min-width: 190px;
  max-width: 400px;
}

@media screen and (min-width: 1500px) {
  .timeline-xl {
    display: block;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1500px) {
  .timeline-md {
    display: block;
  }
}

@media screen and (min-width: 1000px) {
  .responsive-full-half {
    width: 50%;
    height: 100%;
  }

  #page-one-section-one {
    width: 65%;
  }

  #page-one-section-two {
    justify-content: center;
    height: 60%;
  }

  #sharePanelPortrait {
    display: none;
  }

  .inner-container {
    flex-direction: row;
  }

  .page-one-section {
    width: 50%;
  }

  .button-container {
    flex-direction: row;
  }

  .margin-small {
    margin: 5px 5px 5px 5px
  }

  .margin {
    margin-top: 20px;
  }

  .title {
    font-size: 40px;
  }

  #mainLogo {
    width: 300px;
    height: auto;
  }

  #user-date-grid {
    grid-template-columns: auto auto auto auto auto auto;
    width: 80%;
    padding: calc(0.1*100vw);
    padding-top: 0;
    padding-bottom: 0;
  }

  .data-grid-item-value {
    font-size: 24px;
  }

  .data-grid-item-title {
    font-size: 18px;
  }

  #pageTwo {
    background-image: url(./resourses/pageTwoBgRot.png);
  }

  .page-two-section {
    flex-direction: row;
  }

  .section-two-title {
    font-size: 35px;
  }

  .section-two-des {
    width: 70%;
  }
}

@media screen and (max-width: 1000px) {
  #page-one-section-two {
    justify-content: center;
    height: 35vh;
  }

  #mainGif {
    width: auto;
    height: 100%;
    /* margin-top: -15px; */
  }

  .timeline-sm {
    display: block;
  }

  .time-line-card-title {
    font-style: italic;
    font-weight: bolder;
    color: rgb(250, 101, 46) !important;
    text-shadow: rgb(255, 166, 83) 3px 3px;
    font-size: 18px !important;
  }

  .time-line-sub-title {
    font-style: italic;
    font-weight: bold;
    color: #fa7e38 !important;
    text-shadow: 2px 2px rgb(211, 243, 94);
    font-size: 12px !important;
    line-height: 1;
  }

  .time-line-card-content {
    font-size: 10px !important;
    line-height: 1.1 !important;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }

  #sharePanelLandscape {
    display: none;
  }

  .inner-container {
    flex-direction: column;
  }

  .page-one-section {
    width: 100%;
  }

  .button-container {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .margin-small {
    margin: 5px 5px 5px 0px
  }

  .margin {
    margin-top: 7px;
  }

  .title {
    font-size: 40px;
  }

  #mainLogo {
    width: 65%;
    min-width: 220px;
    height: auto;
  }

  .section-two-logo {
    padding-top: 30px;
  }

  #user-date-grid {
    grid-template-columns: auto auto auto;
  }

  .data-grid-item-value {
    font-size: 25px;
  }

  .responsive-full-half {
    width: 100%;
    height: 50%;
  }

  .section-two-title {
    font-size: 26px;
  }

  .section-two-des {
    width: 80%;
    font-size: 24px;
  }

  #secTwoImg {
    width: 45%;
  }
}

@media screen and (max-width: 550px) {
  #nav {
    height: 40px;
  }

  .timeline-headline-title {
    font-size: 30px;
    margin-left: -25px;
  }

  .timeline-headline {
    width: 100%;
    justify-content: flex-start;
    height: 15%;
  }

  .timeline-sm {
    display: block;
  }

  .time-line-card-title {
    font-style: italic;
    font-weight: bolder;
    color: rgb(250, 101, 46) !important;
    text-shadow: rgb(255, 166, 83) 3px 3px;
    font-size: 18px !important;
  }

  .time-line-sub-title {
    font-style: italic;
    font-weight: bold;
    color: #fa7e38 !important;
    text-shadow: 2px 2px rgb(211, 243, 94);
    font-size: 12px !important;
    line-height: 1;
  }

  .time-line-card-content {
    font-size: 10px !important;
    line-height: 1.1 !important;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }

  #mainApp {
    height: calc(100% - 40px);
  }

  .nav-button-text {
    font-size: 12px;
  }

  .title {
    font-size: 26px;
  }

  .page-one-button {
    height: 25px;
  }

  #mainLogo {
    width: 55%;
    min-width: 150px;
    height: auto;
  }

  .data-grid-item-value {
    font-size: 15px;
  }

  .data-grid-item-title {
    font-size: 12px;
  }

  .section-two-des {
    width: 80%;
    font-size: 14px;
  }

  #user-date-grid {
    margin-top: -20px;
  }

  .section-two-logo {
    padding-top: 10px;
  }

  .section-two-title {
    font-size: 22px;
  }

  .section-two-button {
    height: 25px;
  }

}